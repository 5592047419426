<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('fulfilmentPricing.title') | title"></huboo-page-heading>

    <huboo-table
      id="fulfilmentPricingTable"
      @keyup.enter.native="onApplyFilters"
      @row-clicked="onRowSelected"
      @search="handleSearch"
      @filter="handleFilter"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      :server-items-length="serverItemsLength"
      :loading="loading || isDeleteLoading"
      hideSearch
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      :remove="isAdmin"
    >
      <template #filter>
        <huboo-filters
          :filters="filters"
          @filtersClear="onFiltersClear"
          @filtersApply="onApplyFilters"
          :activeFilters="activeFilters"
        >
          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('common.description')"
              v-model="availableFilters.descriptionFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.originRegion')"
              v-model="availableFilters.originRegionFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.destinationRegion')"
              v-model="availableFilters.destinationRegionFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.containerType')"
              v-model="availableFilters.containerTypeCodeFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.shippingService')"
              v-model="availableFilters.shippingServiceCodeFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.destinationZone')"
              v-model="availableFilters.destinationZoneFilter"
            ></v-text-field>
          </v-col>
        </huboo-filters>
      </template>

      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />
        <v-btn @click="onImportCsvClicked" color="primary" :disabled="loading">
          {{ $t('common.importFromCsv') }}
        </v-btn>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="fulfilmentPricingCreateButton"
        >
          {{ $t('billing.createFulfilmentPricing') }}
        </v-btn>
        <v-btn
          :loading="isDeleteLoading"
          id="fulfilmentPricingDeleteAllButton"
          v-if="isAdmin"
          color="error"
          :disabled="noTableData"
          @click="modalToggle"
          ref="delete-all-button"
        >
          {{ $t('fulfilmentPricing.deleteAll') }}
        </v-btn>
      </template>
    </huboo-table>

    <fulfilment-pricing-create-modal
      v-model="createModal"
      @close="closeCreateModal"
      @updated-item="onUpdatedItem"
    />

    <fulfilment-pricing-edit-modal
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
      ref="edit-modal"
    />

    <fulfilment-pricing-upload-modal v-model="uploadModalOpen" />

    <HubConfirmationModal
      :value="bulkDeleteConfirmationModal"
      :cancelText="$t('billing.defaultConfirmationModal.cancel')"
      :confirmText="$t('billing.defaultConfirmationModal.confirm')"
      qaName="default-hub-confirmation"
      @cancel="modalToggle"
      @confirm="confirmBulkDelete"
      danger
    >
      <template #title>
        {{ $t('fulfilmentPricing.confirmationModal.title') }}
      </template>
      <template>
        {{ bulkDeleteModalMessage }}
      </template>
    </HubConfirmationModal>
  </huboo-page>
</template>

<script>
import {
  getContainerTypeCode,
  getRegion,
  getShippingService,
} from '@/views/pages/billing/fulfilmentPricing/fulfilmentPricingModalHelpers'
import FulfilmentPricingEditModal from '@/modules/CustomFulfilmentPricing/FulfilmentPricingEditModal.vue'
import FulfilmentPricingCreateModal from '@/modules/CustomFulfilmentPricing/FulfilmentPricingCreateModal.vue'
import FulfilmentPricingUploadModal from '@/modules/CustomFulfilmentPricing/FulfilmentPricingUploadModal.vue'
import HubooFilters from '@/components/forms/FiltersMenu.vue'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import filtersMixin from '@/mixins/filters'
import ExportButton from '@/components/pages/ExportButton.vue'
import { HubConfirmationModal } from '@hubootech/ui-library'
import ModalActionsMixin from '@/mixins/modalActions'

export default {
  name: 'CustomFulfilmentPricing',
  mixins: [ClientMixin, filtersMixin, ModalActionsMixin],
  components: {
    HubConfirmationModal,
    'fulfilment-pricing-create-modal': FulfilmentPricingCreateModal,
    'fulfilment-pricing-edit-modal': FulfilmentPricingEditModal,
    'fulfilment-pricing-upload-modal': FulfilmentPricingUploadModal,
    'export-button': ExportButton,
    HubooFilters,
  },
  data: () => ({
    createModal: false,
    editModal: false,
    uploadModalOpen: false,
    bulkDeleteConfirmationModal: false,
    editRequiresAdmin: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['updated_at'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
    // filters
    availableFilters: {
      containerTypeCodeFilter: '',
      currencyFilter: '',
      descriptionFilter: '',
      destinationZoneFilter: '',
      destinationRegionFilter: '',
      originRegionFilter: '',
      shippingServiceCodeFilter: '',
      weightFilter: '',
    },
    activeFilters: 0,
  }),
  created() {
    this.$store.dispatch('billing/fetchContainerTypeCodes', { url: 'container-type-codes' })
    this.$store.dispatch('billing/fetchRegions')
    this.$store.dispatch('billing/fetchZones')
    this.$store.dispatch('billing/fetchShippingServices')
  },
  computed: {
    bulkDeleteModalMessage() {
      return this.$t('fulfilmentPricing.confirmationModal.message', {
        priceLinesTotal: this.fulfilmentPriceLists?.meta?.total,
        hubooBox: this.box?.hubooBox,
      })
    },
    exportUrl() {
      return this.fulfilmentPriceLists?.exportUrl
    },

    headers() {
      return [
        {
          text: title(this.$t('common.description')),
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: title(this.$t('billing.originRegion')),
          sortable: false,
          value: 'originRegionName',
        },
        {
          text: title(this.$t('billing.destinationRegion')),
          sortable: false,
          value: 'destinationRegionName',
        },
        {
          text: title(this.$t('billing.destinationZone')),
          sortable: false,
          value: 'destinationZone',
        },
        {
          text: title(this.$t('billing.containerType')),
          sortable: false,
          value: 'containerTypeName',
        },
        {
          text: title(this.$t('common.weight')),
          sortable: false,
          value: 'weight',
        },
        {
          text: title(this.$t('common.price')),
          sortable: false,
          value: 'price',
        },
        {
          text: title(this.$t('billing.additionalPricePerKilo')),
          sortable: false,
          value: 'additionalPricePerKilo',
        },
        {
          text: title(this.$t('common.surcharge')),
          sortable: false,
          value: 'surcharge',
        },
        {
          text: title(this.$t('billing.shippingService')),
          sortable: false,
          value: 'shippingServiceCodeName',
        },
        {
          text: title(this.$t('common.updated')),
          sortable: true,
          value: 'updatedAt',
        },
      ]
    },
    fulfilmentPriceLists() {
      return this.$store.getters['billing/getFulfilmentPriceLists']
    },
    items() {
      return this.fulfilmentPriceLists.data.map(bh => {
        const v = { ...bh }
        const codes = getContainerTypeCode(v.containerId)
        v.containerTypeName = codes?.description + ' (' + codes?.name + ')'
        v.originRegionName = getRegion(v.originRegion)?.name + ' (' + v.originRegion + ')'
        v.destinationRegionName =
          getRegion(v.destinationRegion)?.name + ' (' + v.destinationRegion + ')'
        v.shippingServiceCodeName =
          getShippingService(v.shippingServiceCode)?.name + ' (' + v.shippingServiceCode + ')'
        v.updatedAt = this.formatDate(v.updatedAt)
        return v
      })
    },
    isDeleteLoading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: `huboo-box/${this.box?.hubooBox}/fulfilment-price-list`,
        method: 'DELETE',
      })
    },

    meta() {
      return this.fulfilmentPriceLists?.meta || { total: this.items.length }
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + '/fulfilment-price-list'
    },
    noTableData() {
      return this.items?.length === 0
    },
  },
  watch: {
    'modal.show': function(s) {
      if (s === false) this.fetch()
    },
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    fetch() {
      if (!this.loading) {
        const { options, orderBy, search } = this
        const { page } = options
        const params = {
          page: page || undefined,
          query: search || undefined,
          orderBy: orderBy || undefined,
          itemsPerPage: this.options.itemsPerPage,
          // Filters.
          description: this.availableFilters.descriptionFilter || undefined,
          containerTypeCode: this.availableFilters.containerTypeCodeFilter || undefined,
          destinationZone: this.availableFilters.destinationZoneFilter || undefined,
          destinationRegion: this.availableFilters.destinationRegionFilter || undefined,
          originRegion: this.availableFilters.originRegionFilter || undefined,
          shippingServiceCode: this.availableFilters.shippingServiceCodeFilter || undefined,
        }
        this.$store.dispatch('billing/fetchFulfilmentPriceLists', { params, url: this.url })
      }
    },
    onImportCsvClicked() {
      this.uploadModalOpen = true
    },
    onSelectedUpdated() {
      this.selected = this.$store.getters['billing/getSelectedFulfilmentPriceList']
    },
    onFiltersClear() {
      this.clearFilters(this.availableFilters)
      this.activeFilters = 0
    },
    onApplyFilters() {
      this.applyFilters(this.availableFilters)
    },
    onRemove() {
      this.$store
        .dispatch('billing/removeFulfilmentPriceLists', {
          url: this.url,
          id: this.selected.id,
        })
        .finally(() => {
          this.fetch()
        })
    },
    onRemovePriceListsByHubooBox() {
      const params = {
        description: this.availableFilters.descriptionFilter || undefined,
        containerTypeCode: this.availableFilters.containerTypeCodeFilter || undefined,
        destinationZone: this.availableFilters.destinationZoneFilter || undefined,
        destinationRegion: this.availableFilters.destinationRegionFilter || undefined,
        originRegion: this.availableFilters.originRegionFilter || undefined,
        shippingServiceCode: this.availableFilters.shippingServiceCodeFilter || undefined,
      }

      this.bulkDeleteConfirmationModal = true
      this.$store
        .dispatch('fulfilmentPricing/removeFulfilmentPriceListsByHubooBox', {
          params,
          hubooBox: this.box?.hubooBox,
        })
        .finally(() => this.fetch())
    },
    confirmBulkDelete() {
      this.onRemovePriceListsByHubooBox()
      this.modalToggle()
    },
    modalToggle() {
      this.bulkDeleteConfirmationModal = !this.bulkDeleteConfirmationModal
    },
  },
}
</script>
