<template>
  <huboo-modal :value="value" @input="$emit('input', $event)" max-width="800px">
    <v-card>
      <huboo-form
        @submit="onSubmit"
        :fields="fields"
        :loading="loading"
        :title="$t('billing.fulfilmentPricingCsvUpload') | title"
        id="uploadCsvFulfilmentPricing"
        lazyValidation
        showSubmit
      />
    </v-card>
  </huboo-modal>
</template>

<script>
import { isRequired } from '@/utilities/validations'
import { CSV_PRICING_UPLOAD_TYPES } from '@/utilities/constants'

const { FULFILMENT } = CSV_PRICING_UPLOAD_TYPES

export default {
  name: 'FulfilmentPricingUploadModal',
  components: {},
  props: {
    value: Boolean,
  },
  data: () => ({}),
  computed: {
    fields() {
      return [
        {
          errorMessages: this.validationError('file'),
          label: this.$t('common.file'),
          name: 'file',
          rules: [isRequired],
          component: 'huboo-uploader',
          validateOnBlur: false,
          blurOnEnter: false,
          accept: ['.csv'],
        },
      ]
    },
    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'POST',
        url: this.url,
      })
    },
    url() {
      const box = this.$store.getters['billing/getSelectedBox']
      const hubooBox = box?.hubooBox
      return `/huboo-box/${hubooBox}/import`
    },
  },
  watch: {},
  created() {},
  methods: {
    async onSubmit(e) {
      await this.$store.dispatch('billing/storePricingUpload', {
        url: this.url,
        file: e?.values.file,
        type: FULFILMENT,
      })

      if (!this.hasErrors) {
        this.$emit('input', false)
      }
    },
    validationError(e) {
      return this.$store.getters['core/validationError'](e)
    },
  },
}
</script>

<style lang="scss" scoped></style>
